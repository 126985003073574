.about {
    background-image: url("../public/assets/img/ACFE/acfelogo.png");
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image within the section */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 80px 0; /* Adjust padding as needed */
    position: relative; /* Ensures proper stacking of content */
  }
.text-center.mt-10.aos-init.aos-animate {
    margin-top: 25px;
}

.aboutpara {

}
section#home {
  background-image: linear-gradient(45deg, black, #c0a4a4);
}
section#about {
  background-image: linear-gradient(45deg, rgb(173, 162, 162), #271c1c);
  color: #ffffff;

}
section#portfolio {
  background-image: linear-gradient(45deg, rgb(173, 162, 162), #271c1c);
  color: #ffffff;

}
section#contact {
  background-image: linear-gradient(45deg, rgb(173, 162, 162), #271c1c);
  color: #ffffff;

}
.aboutus{
  color: aliceblue;
  text-transform: capitalize;

}
.cardtit{
  color: rgb(218, 0, 0);
  text-transform: capitalize;

}
.bi-zoom-in{
color: rgb(12, 184, 35);
}

.heading-text {
    transition: opacity 0.5s ease-in-out; /* Example transition effect */
  }
  
  .changing-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.list-group-item {
  text-align: left;
}


.carousel-caption {
  background-color: rgba(252, 252, 252, 0.5); /* Semi-transparent background */
  padding: 20px; /* Padding inside the caption */
}
.carousel-caption h5 {
  font-size: 2rem; /* Larger font size */
  font-weight: bold; /* Bold text */
  color: #ffffff; /* White text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for emphasis */
}
.carousel-caption p {
  font-size: 1.5rem; /* Smaller font size for description */
  color: #ffffff; /* White text color */
}
.register{
  background-color: rgb(198, 218, 205);
}
h1.text-light {
  transition: opacity 1.3s ease-in-out;
}


.blinking-text {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}



.btn-get-started {
  background: linear-gradient(to right, #000, #536674); /* Blue-black gradient */
  color: rgb(255, 255, 255);
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2); /* Subtle shimmer */
}
.card{
  padding: 0;
  
}
.btn-get-started:hover {
  background: linear-gradient(to bottom, #000, #f7f7f7);
   /* Reverse gradient on hover */
   color: beige;
   box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.4); /* Increased shimmer on hover */
}
